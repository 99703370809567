import React from "react"
import { Link } from "gatsby"
import classNames from "classnames/bind"
import Layout from "../../components/layout"
import PagePromo from "../../components/PagePromo/PagePromo"
import Badge from "../../components/Badge/Badge"
import ProposalCTA from "../../components/ProposalCTA/ProposalCTA"
import SEO from "../../components/seo"
import ToolkitTool from "../../components/ToolkitTool"
import styles from "./curated.module.css"
import toolkitStyles from "./toolkit.module.css"

const cx = classNames.bind(styles)

const designTools = {
  name: "Design & Ideation",
  tools: [
    {
      name: "Sketch",
      description: "Interface design toolkit",
      icon: "/tools/icon-sketch.jpeg",
      url: "https://www.sketch.com/",
    },
    {
      name: "Figma",
      description: "The collaborative design tool",
      icon: "/tools/icon-figma.jpeg",
      url: "https://www.figma.com/",
    },
    {
      name: "Framer",
      description: " An interactive prototyping tool for teams",
      icon: "/tools/icon-framer.png",
      url: "https://www.framer.com/",
    },
    {
      name: "Invision",
      description: "Prototyping and visualisation tool",
      icon: "/tools/icon-invision.png",
      url: "https://www.invisionapp.com/",
    },
    {
      name: "Miro",
      description: "Multi-task board for design processes",
      icon: "/tools/icon-miro.png",
      url: "https://miro.com/",
    },
    {
      name: "Whimsical",
      description: "Flowcharts, mindmaps & wireframes",
      icon: "/tools/icon-whimsical.png",
      iconImgCrop: true,
      url: "https://whimsical.com/",
    },
    {
      name: "Maze",
      description: "Prototyping and user testing",
      icon: "/tools/icon-maze.png",
      url: "https://maze.design/",
    },
    {
      name: "Useberry",
      description: "Prototyping and user testing",
      icon: "/tools/icon-useberry.png",
      url: "https://www.useberry.com/",
    },
    {
      name: "Marvel",
      description:
        "Wireframe, prototype, user test and inspect designs in one place",
      icon: "/tools/icon-marvel.png",
      url: "https://marvelapp.com/",
    },
  ],
}

const analyticsTools = {
  name: "Research & Analytics",
  tools: [
    {
      name: "Typeform",
      description: "Easy forms and surveys",
      icon: "/tools/icon-typeform.png",
      url: "https://www.typeform.com/",
    },
    {
      name: "Google Optimize",
      description: "Website A/B testing",
      icon: "/tools/icon-google-optimize.png",
      iconImgCrop: true,
      url: "https://marketingplatform.google.com/",
    },
    {
      name: "Google Analytics",
      description: "Web analytics",
      icon: "/tools/icon-google-analytics.png",
      iconImgCrop: true,
      url: "https://analytics.google.com/",
    },
    {
      name: "Hotjar",
      description: "Analyze your users with heatmaps & recordings",
      icon: "/tools/icon-hotjar.png",
      iconImgCrop: true,
      url: "https://www.hotjar.com/",
    },
    {
      name: "Mixpanel",
      description: "Detailed product analytics",
      icon: "/tools/icon-mixpanel.png",
      iconImgCrop: true,
      url: "https://mixpanel.com/",
    },
  ],
}

const managementTools = {
  name: "Management",
  tools: [
    {
      name: "Notion",
      description: "Flexible workspace for your notes, tasks, and wikis",
      icon: "/tools/icon-notion.jpeg",
      iconImgCrop: true,
      url: "https://www.notion.so/",
    },
    {
      name: "Asana",
      description: "Board-based project management",
      icon: "/tools/icon-asana.png",
      iconImgCrop: true,
      url: "https://app.asana.com/",
    },
    {
      name: "Airtable",
      description: "Platform for creating and sharing relational databases",
      icon: "/tools/icon-airtable.png",
      url: "https://airtable.com/",
    },
    {
      name: "Monday",
      description:
        "Run processes, projects and workflows in one digital workspace",
      icon: "/tools/icon-monday.png",
      url: "https://www.monday.com/",
    },
    {
      name: "Clubhouse",
      description:
        "Intuitive project management platform for development teams",
      icon: "/tools/icon-clubhouse.png",
      url: "https://clubhouse.io/",
    },
    {
      name: "Slack",
      description: "Channel-based messaging platform for teams",
      icon: "/tools/icon-slack.png",
      iconImgCrop: true,
      url: "https://slack.com/",
    },
    {
      name: "Loom",
      description: "Screencasting tool for Chrome",
      icon: "/tools/icon-loom.jpg",
      url: "https://www.loom.com/",
    },
    {
      name: "Linear",
      description: "Streamlined issue tracking for software teams",
      icon: "/tools/icon-linear.png",
      iconImgCrop: true,
      url: "https://linear.app/",
    },
  ],
}

const nocodeTools = {
  name: "No-Code Tools",
  tools: [
    {
      name: "Shopify",
      description: "Sell products online with your own online store",
      icon: "/tools/icon-shopify.png",
      iconImgCrop: true,
      url: "https://www.shopify.com/",
    },
    {
      name: "Webflow",
      description: "Responsive Web Design Tool",
      icon: "/tools/icon-webflow.png",
      iconImgCrop: true,
      url: "https://webflow.com/",
    },
    {
      name: "Sharetribe",
      description: "Build your own marketplace",
      icon: "/tools/icon-sharetribe.png",
      url: "https://www.sharetribe.com/",
    },
    {
      name: "Teachable",
      description: "Build your online course business",
      icon: "/tools/icon-teachable.png",
      url: "https://teachable.com/",
    },
    {
      name: "Bubble",
      description: "Build web apps without code",
      icon: "/tools/icon-bubble.jpg",
      iconImgCrop: true,
      url: "https://bubble.io/",
    },
    {
      name: "Mailchimp",
      description: "Marketing automation",
      icon: "/tools/icon-Mailchimp.png",
      url: "https://mailchimp.com/",
    },
    {
      name: "MemberStack",
      description: "Easy user login & payments",
      icon: "/tools/icon-memberstack.png",
      iconImgCrop: true,
      url: "https://www.memberstack.com/",
    },
    {
      name: "Zapier",
      description: "Automate your processes",
      icon: "/tools/icon-zapier.png",
      url: "https://zapier.com/",
    },
  ],
}

const developerTools = {
  name: "Developer Tools",
  tools: [
    {
      name: "Firebase",
      description: "Quickly develop high-quality web & mobile apps",
      icon: "/tools/icon-firebase.png",
      iconImgCrop: true,
      url: "https://firebase.google.com/",
    },
    {
      name: "GitHub",
      description: "Version control for developer collaboration",
      icon: "/tools/icon-github.png",
      url: "https://github.com/",
    },
    {
      name: "Gatsby",
      description:
        "React-based Front-end platform with performance & scalability in mind",
      icon: "/tools/icon-gatsby.png",
      iconImgCrop: true,
      url: "https://www.gatsbyjs.com/",
    },
    {
      name: "Netlify",
      description: "Powerful serverless hosting",
      icon: "/tools/icon-netlify.png",
      iconImgCrop: true,
      url: "https://www.netlify.com/",
    },
    {
      name: "Vercel",
      description: "Rapid hosting & deployment platform",
      icon: "/tools/icon-vercel.png",
      iconImgCrop: true,
      url: "https://vercel.com/",
    },
    {
      name: "Prismic",
      description: "Flexible Content Management System",
      icon: "/tools/icon-prismic.png",
      url: "https://prismic.io/",
    },
    {
      name: "Postmark",
      description: "Send transactional emails",
      icon: "/tools/icon-postmark.png",
      url: "https://postmarkapp.com/",
    },
    {
      name: "Sentry",
      description: "Error monitoring",
      icon: "/tools/icon-sentry.png",
      iconImgCrop: true,
      url: "https://sentry.io/",
    },
    {
      name: "Testflight",
      description: "Beta testing for ios apps",
      icon: "/tools/icon-testflight.png",
      url: "https://testflight.apple.com/",
    },
  ],
}

const toolkit = () => {
  return (
    <Layout>
      <SEO title="Toolkit" />
      <div className={toolkitStyles.introDetails}>
        <div className={toolkitStyles.introDetails1} />
      </div>
      <div className={toolkitStyles.container}>
        <div className={toolkitStyles.hero}>
          <nav className={toolkitStyles.breadcrumbs}>
            <Link to="/toolkit">TOOLKIT</Link>
            <span>/</span>CURATED TOOLS
          </nav>
          <h3 className={toolkitStyles.subtitle}>
            Our curated list of tools for product builders
          </h3>
          <p className={toolkitStyles.small_text}>
            A collection of the best practices & tools, battle-tested by us.
          </p>
        </div>

        <div className={styles.mainContent}>
          <Badge title={designTools.name} />
          <h3 className={styles.sectionTitle}></h3>
          <div>
            {designTools.tools.map(tool => {
              return <ToolkitTool tool={tool} key={tool.name} />
            })}
          </div>
          <Badge title={analyticsTools.name} color="Orange" />
          <div>
            {analyticsTools.tools.map(tool => {
              return <ToolkitTool tool={tool} key={tool.name} />
            })}
          </div>
          <Badge title={managementTools.name} color="Green" />
          <div>
            {managementTools.tools.map(tool => {
              return <ToolkitTool tool={tool} key={tool.name} />
            })}
          </div>

          <Badge title={nocodeTools.name} color="Red" />
          <div>
            {nocodeTools.tools.map(tool => {
              return <ToolkitTool tool={tool} key={tool.name} />
            })}
          </div>
          <Badge title={developerTools.name} color="Purple" />
          <div>
            {developerTools.tools.map(tool => {
              return <ToolkitTool tool={tool} key={tool.name} />
            })}
          </div>
        </div>

        <div className={styles.footerContent}>
          <PagePromo
            title="Product Lexicon"
            text="Our digital jargon-buster, including key words and phrases commonly used by designers and product developers."
            link="/toolkit/product-lexicon"
            image="/tools/lexicon_w_background.png"
          />
          <ProposalCTA
            title="Creative product design and ideation to make your ideas a reality"
            text="Get specialised ideation support, design & development to make your ideas come to life."
            link="/contact"
            linkText="👋 Get in touch"
          />
        </div>
      </div>
    </Layout>
  )
}

export default toolkit
